<template>
  <div class="accountInformation">
    <h1 class="title">Account</h1>
    <span class="subtitle">Pas hier je wachtwoord aan</span>
    <div class="form">
      <div class="formWrapper__form--inputWrapper">
        <a-form-item
          :validate-status="($v.accountInformation.oldPassword.name.$dirty && $v.accountInformation.oldPassword.name.$invalid) ? 'error' : 'success'"
        >
          <template #help>
            <span
              v-show="$v.accountInformation.oldPassword.name.$dirty && $v.accountInformation.oldPassword.name.$invalid"
            >
              Onjuiste invoer
              {{ $v.accountInformation.oldPassword.sameAsPassword }}
            </span>
          </template>
          <a-input-password
            allow-clear
            id="oldPassword"
            v-model="accountInformation.oldPassword.name"
            size="large"
            class="formWrapper__form--input"
            @focus="focusHandler('oldPassword', true, 'accountInformation')"
            @blur="focusHandler('oldPassword', false, 'accountInformation')"
          />
        </a-form-item>
        <label for="oldPassword"
               class="formWrapper__form--label"
               :class="accountInformation.oldPassword.isFocused ? 'focused' : 'sdcc'"
        >
          Huidig wachtwoord
        </label>
      </div>
      <div class="form__inlineInputs">
        <div class="formWrapper__form--inputWrapper">
          <a-form-item
            :validate-status="($v.accountInformation.password.name.$dirty && $v.accountInformation.password.name.$invalid) ? 'error' : 'success'"
          >
            <template #help>
              <span
                v-show="$v.accountInformation.password.name.$dirty && $v.accountInformation.password.name.$invalid"
              >
                 Onjuiste invoer. Het wachtwoord moet minimaal 10 tekens bevatten
              </span>
            </template>
            <a-input-password
              allow-clear
              id="password"
              v-model="accountInformation.password.name"
              size="large"
              class="formWrapper__form--input"
              @focus="focusHandler('password', true, 'accountInformation')"
              @blur="focusHandler('password', false, 'accountInformation')"
            />
          </a-form-item>

          <label for="password"
                 class="formWrapper__form--label"
                 :class="accountInformation.password.isFocused ? 'focused' : ''"
          >
            Nieuw wachtwoord
          </label>
        </div>
        <div class="formWrapper__form--inputWrapper">
          <a-form-item
            :validate-status="($v.accountInformation.repeatPassword.name.$dirty && $v.accountInformation.repeatPassword.name.$invalid) ? 'error' : 'success'"
          >
            <template #help>
              <span
                v-show="$v.accountInformation.repeatPassword.name.$dirty && $v.accountInformation.repeatPassword.name.$invalid"
              >
                Onjuiste invoer. Het wachtwoord moet minimaal 10 tekens bevatten
                  {{ $v.accountInformation.repeatPassword.sameAsPassword }}
              </span>
            </template>
            <a-input-password
              allow-clear
              id="repeatPassword"
              v-model="accountInformation.repeatPassword.name"
              size="large"
              class="formWrapper__form--input"
              @focus="focusHandler('repeatPassword', true, 'accountInformation')"
              @blur="focusHandler('repeatPassword', false, 'accountInformation')"
            />
          </a-form-item>

          <label for="repeatPassword"
                 class="formWrapper__form--label"
                 :class="accountInformation.repeatPassword.isFocused ? 'focused' : 'sdcc'"
          >
            Herhaal je nieuwe wachtwoord
          </label>
        </div>
      </div>
      <a-alert
        :message="errorMessage"
        type="error"
        close-text="Nu sluiten"
        v-if="errorMessage.length"
        style="margin-bottom: 10px"
      />
      <a-alert
        :message="successMessage"
        type="success"
        close-text="Nu sluiten"
        v-if="successMessage.length"
        style="margin-bottom: 10px"
      />
      <a-button type="primary"
                @click="submitHandler"
      >
        Opslaan
        <a-icon type="right"/>
      </a-button>
    </div>
  </div>
</template>

<script>
import {minLength, required, sameAs} from "vuelidate/lib/validators";
import focus from "@/mixins/focus";
import {mapActions} from "vuex";

export default {
  name: "AccountInformation",
  mixins: [focus],
  data() {
    return {
      loading: false,
      errorMessage: '',
      successMessage: '',
      accountInformation: {
        oldPassword: {
          name: '',
          isFocused: false
        },
        password: {
          name: '',
          isFocused: false
        },
        repeatPassword: {
          name: '',
          isFocused: false
        },
      }
    }
  },
  methods: {
    ...mapActions([
      'updateAccountInformation'
    ]),
    submitHandler() {
      this.$v.accountInformation.$touch()
      if (!this.$v.accountInformation.$invalid) {
        this.updateAccountInformation({
          oldPassword: this.accountInformation.oldPassword.name,
          newPassword: this.accountInformation.password.name,
          repeatPassword: this.accountInformation.repeatPassword.name
        }).then(res => {
          if (!res.ok) {
            this.errorMessage = res.message || 'Er is iets misgegaan'
          } else {
            this.successMessage = 'Je hebt succesvol je wachtwoord aangepast!'
          }
        })
        .finally(() => setTimeout(() => {
          this.errorMessage = ''
          this.successMessage = ''
        }, 5000))
      }
    },
  },
  validations: {
    accountInformation: {
      oldPassword: {
        name: {
          required,
        }
      },
      password: {
        name: {
          required,
          minLength: minLength(10)
        }
      },
      repeatPassword: {
        name: {
          required,
          minLength: minLength(10),
          sameAsPassword: sameAs(function () {
            return this.accountInformation.password.name;
          })
        }
      },
    },
  },
}
</script>

<style lang="scss">
.form__avatar {
  display: flex;
  justify-content: center;
}

.avatarAlt {
  fill: #FFA300;
}

.avatarPreview {
  width: 125px;
  height: 125px;
  object-fit: cover;
}

.avatar__button {
  background: rgba(#F15A24, 1);
  padding: 5px 10px;
  width: 100%;
  border-radius: 10px;
}
</style>
